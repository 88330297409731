import { isValid, isDirty, submit, getFormValues } from 'redux-form'
import deepEqual from 'deep-equal'
import { Modal } from '../../components/common'
import { isEmpty } from '../../utils/common'
import { openModal } from '../../actions/app'
import {
  SELECT_SPECIAL_DIGITAL_FORMATS_FORM,
  UPDATE_TYPE_DIGITAL_ACCOMMODATIONS,
  SDF_MULTIDAY,
} from '../../constants/StudentConstants'
import SelectSpecialDigitalFormatsToBeOrderedForm from '../forms/SelectSpecialDigitalFormatsToBeOrderedForm'
import { getOrderDeadlineIsPast } from '../../selectors/section'
import { resetStudentUpdate } from '../../actions/studentsCommon'

const mapStateToProps = (state, { examId }) => {
  const {
    status: {
      data: { isSubmitted },
    },
    settingsEducationPeriod: { selectedEducationPeriod },
    settingsDeadlines: { data: settingsDeadlinesData },
    studentsByOrg: {
      update: { type, updating, error, id },
      courseMap,
      studentMap,
      sectionMap,
      exams,
    },
  } = state
  const deadlinesData = settingsDeadlinesData?.[selectedEducationPeriod] ?? {}
  const exam = exams.find(e => e.examId === examId)
  const isActive = type === UPDATE_TYPE_DIGITAL_ACCOMMODATIONS && exam.examId === id
  const { lastName, firstName } = studentMap?.[exam.studentId] ?? {}
  const { specialDigitalFormats = {} } = exam

  return {
    active: isActive,
    updating: isActive && updating,
    error: isActive && error,
    valid: isValid(SELECT_SPECIAL_DIGITAL_FORMATS_FORM)(state),
    dirty: isDirty(SELECT_SPECIAL_DIGITAL_FORMATS_FORM)(state),
    selectedSpecialDigitalFormats: getFormValues(SELECT_SPECIAL_DIGITAL_FORMATS_FORM)(state) || {},
    exam,
    courseName: courseMap[exam.testCd].name,
    studentName: `${firstName} ${lastName}`,
    isChangeOrder: isSubmitted || getOrderDeadlineIsPast(sectionMap[exam.sectionId], deadlinesData),
    hasMultidayTesting: specialDigitalFormats[SDF_MULTIDAY] !== undefined,
  }
}

class OrderSpecialDigitalFormatsModal extends Component {
  state = { shouldCloseModal: false, active: false }

  constructor(props) {
    super(props)
    this.onCloseAction = this.onCloseAction.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.previousDigitalAccommodations = props.exam.digitalAccommodations
  }

  static getDerivedStateFromProps(props, state) {
    if (state.active && !props.active) return { shouldCloseModal: true }

    return { active: props.active }
  }

  componentWillUnmount() {
    const { resetStudentUpdate } = this.props
    resetStudentUpdate()
  }

  onCloseAction() {
    const { openModal, exam, courseName, studentName } = this.props

    if (this.showChangeOrderModal()) {
      openModal('UpdateStudentExamSuccessModal', {
        exam,
        type: UPDATE_TYPE_DIGITAL_ACCOMMODATIONS,
        courseName,
        studentName,
        modalCloseFocusElem: this.modalCloseFocusElem,
      })
    }
  }

  submitForm() {
    const { submit } = this.props
    submit(SELECT_SPECIAL_DIGITAL_FORMATS_FORM)
  }

  footerActions() {
    const { valid, updating, dirty } = this.props
    return [
      {
        buttonLabel: 'Cancel',
        isDismissable: true,
        isPrimary: false,
        isDisabled: updating,
      },
      {
        buttonLabel: 'Update',
        isPrimary: true,
        onClick: this.submitForm,
        isDisabled: !valid || updating || !dirty,
        busy: updating,
      },
    ]
  }

  showChangeOrderModal() {
    const {
      isChangeOrder,
      exam: { digitalAccommodations },
    } = this.props

    return (
      isChangeOrder &&
      !isEmpty(digitalAccommodations) &&
      !deepEqual(digitalAccommodations, this.previousDigitalAccommodations, { strict: true })
    )
  }

  render() {
    const { exam, courseName, hasMultidayTesting, modalCloseFocusElem } = this.props
    const { shouldCloseModal } = this.state

    return (
      <Modal
        shouldCloseModal={shouldCloseModal}
        onCloseAction={this.onCloseAction}
        modalCloseFocusElem={modalCloseFocusElem}
        headerTitle={`${courseName} Digital Exam: Special Format for Students with Accommodations`}
        footerActions={this.footerActions()}
      >
        <p>
          This student will receive a format of the digital exam with the accommodations listed
          below. Confirm that the accommodations listed are accurate for this exam. If the student
          wants to waive any of these accommodations for this digital exam, you&#39;ll need to
          de-select those accommodations here and then click <strong>Update</strong> at the bottom
          of the screen.
        </p>
        <p>
          If the accommodations listed below are accurate and the student doesn&#39;t want to make
          any changes, no further action is needed.
        </p>
        <p>
          <strong>Note:</strong> As with paper-and-pencil exams, some types of accommodations
          require students to take the digital exam in separate rooms from other test-takers. Refer
          to the <em>AP SSD Guidelines</em> for details about accommodations that require separate
          testing rooms.
        </p>
        <SelectSpecialDigitalFormatsToBeOrderedForm exam={exam} />
      </Modal>
    )
  }
}

export default connect(mapStateToProps, {
  submit,
  openModal,
  resetStudentUpdate,
})(OrderSpecialDigitalFormatsModal)
